
export default {
  props: {
    title: {
      type: String,
      default: ``,
    },
    teamId: {
      type: [String, Boolean],
      default: false,
    },
    seasonId: {
      type: [String, Boolean],
      default: false,
    },
    stageId: {
      type: [String, Boolean],
      default: false,
    },
    matchday: {
      type: [String, Boolean],
      default: false,
    },
  },
  data () {
    return {
      table: [],
      stats: [
        { abbr: `Pos`, title: `Position`, key: `position` },
        { abbr: `Team`, title: `Team`, key: `team` },
        { abbr: `PL`, title: `Played`, key: `played` },
        { abbr: `W`, title: `Wins`, key: `wins` },
        { abbr: `D`, title: `Draws`, key: `draws` },
        { abbr: `L`, title: `Losses`, key: `losses` },
        { abbr: `GD`, title: `Goal Difference`, key: `goal_difference` },
        { abbr: `Pts`, title: `Points`, key: `points` },
      ],
      stage: {},
      blockTitle: ``,
    };
  },
  computed: {
    sortedTable () {
      return [...this.table]
        .filter(team => team)
        .sort((a, b) => a.position - b.position);
    },
    season () {
      return this.seasonId || this.getDefault(`season`).id; // Return prop or get default season
    },
  },
  mounted () {
    this.blockTitle = this.title;
    if (this.season) { // Don't break the page if a season hasn't been set
      this.getTable();
    }
  },
  methods: {
    async getTable (overrideMatchday = false) {
      const params = {
        include: `stage.season.competition,squad.team`,
        paginate: 12, // Number of teams in the league
      };

      if (this.matchday && !overrideMatchday) {
        params.filter = {
          "stageId": this.stageId,
          "matchday": this.matchday,
        };
        params.sort = {
          "position": `asc`,
        };
      } else {
        params.filter = {
          "stage.seasonId": this.season,
        };
        params.sort = {
          "matchday": `desc`,
        };
      }

      const response = await this.$getStats(`ladder`, params);
      if (response !== null) {
        this.table = response.data;
        if (overrideMatchday) {
          this.blockTitle = `League Table`;
        }
      }

      // If matchday table doesn't exist, get latest table and update title
      if (!this.table.length && !overrideMatchday) {
        this.getTable(true);
      }
    },
    isActive (team) {
      return this.teamId
        ? team?.squad?.team?.id === this.teamId
        : false;
    },
    getValue (team, stat) {
      if (stat.key === `team`) {
        return team?.squad?.team?.teamNames[0].shortName || ``;
      } else if (stat.key === `goal_difference`) {
        return team.goalsFor - team.goalsAgainst;
      }
      return team[stat.key];
    },
  },
};
