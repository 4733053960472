
export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      sections: [`next_match`, `fixtures`, `table`],
      activeTab: 0, // Don't like using index as key, but it's required here
      nextMatchTab: `Next Match`,
    };
  },
  computed: {
    blocks () {
      return this.post.acf.home_config.match_components;
    },
    tabs () {
      const tabs = [this.nextMatchTab];
      for (const section of this.sections) {
        if (this.blocks[section].block_title) {
          tabs.push(this.blocks[section].block_title);
        }
      }

      return tabs;
    },
    nextMatchTeam () {
      return this.post.acf.home_config?.match_components?.next_match?.team_override?.team.length
        ? this.post.acf.home_config?.match_components?.next_match?.team_override?.team[0]
        : this.getDefault(`team`);
    },
    nextMatchRefresh () {
      return this.post.acf.home_config?.match_components?.next_match?.refresh_time * 1000 || 20000;
    },
  },
  methods: {
    setNextMatchTab (title) {
      this.nextMatchTab = title;
    },
  },
};
